import bugsnag from '@bugsnag/js'

import 'bootstrap';

import validate from 'jquery-validation'

$.validator.addMethod(
        "regex",
        function(value, element, regexp) {
            var re = new RegExp(regexp);
            return this.optional(element) || re.test(value);
        },
        "Tjek det indtastede og prøv igen"
);

$.validator.addMethod("cpr", function(value, element) {
    return this.optional(element) || is_valid_cpr(value);
}, "* Invalid CPR");

if (module.hot) {
  module.hot.accept();
}

if(window.location.hostname == 'localhost') {
	var apiEndpoint = 'http://periamma.test/reepay.php';
} else {
	var apiEndpoint = 'https://donate.periamma.org/reepay.php';
}

/**
 * Validate CPR
 */

function is_valid_cpr(cpr) {
		var original = cpr;
		cpr = cpr.replace('-', '');
		cpr = cpr.replace(/[ \D]/ig, "");
		if (typeof cpr === "undefined" || cpr === "" || cpr.length!==10) {
				return false;
		};


		var main_int = 0;
		var factors = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
		for (var ciffer = 0; ciffer < cpr.length; ciffer++) {
				main_int += parseInt(cpr.substr(ciffer, 1)) * factors[ciffer];
		}
		return main_int % 11 == 0;
}

window.is_valid_cpr = is_valid_cpr;

/**
 * Donation Form
 */

	var DonationForm;
	var BugSnag;

	/**
	 * Donation types:
	 * 1 = Sponsor (a child)
	 * 2 = Support member
	 *
	 * Steps:
	 * 1) Choose donation type
	 * 2) Choose donation details (sponsor a child=which child, support member= what amount?)
	 * 3) Personal details
	 * 4) CPR (only shown if not VAT number is provided)
	 * 5) Payment details
	 * 6) Thank you screen
	 * 7) Something went wrong (during createSubscription progress)
	 */

	DonationForm = {
		init: function() {
			this.container = $('#donation-form');
			this.step = 1;

			this.rp = new Reepay.ModalCheckout(); // reepay payment instance
			this.rpSessionId = null; // reepay session id
			this.hasPaymentInfo = false; // do we have payment info?

			this.rpCustomerHandle = null;
			this.rpPaymentMethod = null;

			this.sponsorType = null; // 1 = child, 2 = support member

			this.loading = 0; // is loading state

			this.formFields = {
				donationType: 1,

				gender: null,
				childCountry: null,
				billingPlan: null,
				
				sponsorAmount: null,
				customSponsorAmount: null,

				firstname: null,
				lastname: null,
				email: null,
				phone: null,
				vat: null,
				address: null,
				country: null,
				city: null,
				zip: null,

				cpr: null
			};

			this.bindEvents();
			this._isLoading(0);

			return this;
		},

		bindEvents: function() {
			var that = this;

			// go next step
			that.container.find('.btn-complete-step').on('click', function() {
				that.next();
			});

			// open-payment-modal
			that.container.find('.open-payment-modal').on('click', function() {
				that._openModalCheckout();
			});

			// cpr
			that.container.find('#inputCpr').keyup(function() {
				var foo = $(this).val().split("-").join(""); // remove hyphens
				
				if (foo.length > 0) {
					foo = foo.match(new RegExp('.{1,6}', 'g')).join("-");
				}

				$(this).val(foo);
			});

			// btn-group-customChooser
			that.container.on('click', '.btn-group-customChooser button', function() {
				var group = $(this).parents('.btn-group-customChooser');

				/**
				 * Set active state
				 */
				
					group.find('button').removeClass('active');
					$(this).addClass('active');

				/**
				 * Set form data
				 */
				
					var value = $(this).text();
					var inputName = group.data('field-name');
					
					group.data('field-value', value);				
					that.formFields[inputName] = value;

				/**
				 * show custom amount if chosen 'Other'
				 */
				
					if(inputName == 'sponsorAmount') {
						that.container.find('.chooseCustomAmount').hide();

						if($(this).hasClass('setCustomAmount')) {
							that.container.find('.chooseCustomAmount').show();

							that.container.find('.chooseCustomAmount').find('input').focus();
						}
					}
			});

			// choose sponsor type
			that.container.on('click', '.chooseSponsorType', function() {
				that.sponsorType = $(this).data('sponsorType');

				that.next();
			});

			// go back
			that.container.on('click', 'button.previous', function() {
				that.back();
			});

			// skip CPR
			that.container.on('click', '.skipCprValidation', function() {
				that.next(false);
			});

			// set formFields on blur
			that.container.on('blur', 'input', function() {
				var inputName = $(this).attr('name');

				if(inputName in that.formFields) {
					that.formFields[inputName] = $(this).val();
				}
			});

			// labels
			that.container.on('change keyup keydown', 'input', function() {
				var group = $(this).parents('.form-label-group');

				if($(this).val() !== '') {
					group.addClass('hasValue');
				} else {
					group.removeClass('hasValue');
				}
			});

			// reepay checkout event handler
			that.rp.addEventHandler(Reepay.Event.Accept, function(data) {
				console.log('Success', data);

				// The customer handle will be in this
				console.log(data.customer);

				// The customers payment information will be in this
				console.log(data.payment_method);

				that.rpCustomerHandle = data.customer;
				that.rpPaymentMethod = data.payment_method;

				that._subscribe(); // create subscription
			});

			// form validation
			that.container.validate( {
				debug: true,
				rules: {
					firstname: "required",
					lastname: "required",
					phone: {
						minlength: 6,
						regex: "[0-9\-\(\)\s]+",
						required: true
					},
					email: {
						required: true,
						email: true
					},
					vat: {
						required: false
					},
					address: {
						required: true
					},
					zip: {
						required: true
					},
					city: {
						required: true
					},
					country: {
						required: true
					},

					// needs to validate seperately
					cpr: {
						minlength: 11,
						maxlength: 11,
						required: function() {
							if(that.formFields.vat) {
								return false;
							}

							return true;
						},
						cpr: true,
					}
				},
				errorElement: "em",
				errorPlacement: function ( error, element ) {
					// could show a error message pop-down...

					return true;
				},
				highlight: function ( element, errorClass, validClass ) {
					$( element ).addClass( "is-invalid" ).removeClass( "is-valid" );
				},
				unhighlight: function (element, errorClass, validClass) {
					$( element ).addClass( "is-valid" ).removeClass( "is-invalid" );
				}
			} );

		},

		userInputError: function(message) {
			alert(message);
		},


		_internalDebug: function(message) {
			var notice = {
				message: message,
				step: this.step,
				formFields: this.formFields
			}

			console.log('## DEBUG MESSAGE', notice);
		},

		_internalError: function(message, data) {
			var error = {
				message: message,
				step: this.step,
				formFields: this.formFields,
				data: data
			};

			console.log('Internal error:', error);

			alert('Something went wrong. Please try again');

			BugSnag.notify(new Error(error));
		},

		_goToStep: function(step) {
			this.step = step;

			this._isLoading(0);
			this._showCurrentStep();
			this._internalDebug('Going to step ' + this.step);
		},

		_validateStep: function() {
			var that = this;

			switch(that.step) {
				case 1:
					// no validation
					break;

				case 2:
					// ensure gender, country, and 

					if(that.sponsorType == 1) { // child
						if(!that.formFields['gender'] || !that.formFields['childCountry'] || !that.formFields['billingPlan']) {
							that.userInputError('Please fill out all fields');
							return false;
						}
					} else { // support member

						if(!that.formFields['billingPlan'] || !that.formFields['sponsorAmount']) {
							that.userInputError('Please fill out all fields');
							return false;
						}

						if(that.formFields['sponsorAmount'] == 'Other..' && !that.formFields['customSponsorAmount']) {
							that.userInputError('Please fill out a custom amount');

							return false;
						}
					}

					break;

				case 3: // personal information

					if(!that.container.valid()) {
						that.userInputError('Please check your input');
						return false;
					}

					break;

				case 4: // CPR

					if(!that.container.valid() && that.formFields['cpr']) {
						that.userInputError('Udfyld et gyldigt CPR nr.');
						return false;
					}

					break;

				case 5: // Payment information

					if(!that.hasPaymentInfo)
						return false;

					break;
			}


			return true; // validation success
		},

		next: function(validate) {

			if(typeof validate === 'undefined' || validate === null)
				validate = true;

			if(validate && !this._validateStep())
				return false; // validation failed

			this.step++;
			this._goToStep(this.step);
		},

		back: function() {
			this.step--;
			this._goToStep(this.step);
		},

		_isLoading: function(state) {
			this.loading = state;

			if(state) {
				this.container.addClass('is-loading');
			} else {
				this.container.removeClass('is-loading');
			}
		},

		_handleStepAction: function() {
			var that = this;

			switch(that.step) {
				case 1:
					
					break;

				case 2:
					if(that.sponsorType == 1) { // 1= child
						that.container.find('.sponsorTypeSupportMember').hide();
						that.container.find('.sponsorTypeChildSupporter').show();
					} else {
						that.container.find('.sponsorTypeSupportMember').show();
						that.container.find('.sponsorTypeChildSupporter').hide();
					}

					break;

				case 4:
					// skip this step if we have a CVR
					if(that.formFields.vat)
						that.next(false);

					break;

				case 5:
					// collect payment information
					that._openModalCheckout();
					break;
			}
		},

		_showCurrentStep: function() {
			this.container.find('.step').removeClass('active');
			this.container.find('.step[data-step=' + this.step + ']').addClass('active');

			this.container.find('.step[data-step=' + this.step + ']')[0].scrollIntoView();

			this._handleStepAction();
		},

		_createReepayCustomer: function() {
			var that = this;

			that._isLoading(1);

			$.ajax({
				type: "POST",
				url: apiEndpoint + '?createCustomer',
				data: that.formFields,
				success: function(response) {
					console.log('Got answer', response);

					that._isLoading(0);
					that.rpSessionId = response.id;
					that._openModalCheckout();
				},
				error: function(response) {
					that._internalError('Somethig went wrong', response);
					that._goToStep(3);
				},
				dataType: 'json'
			});
		},

		_subscribe: function() {
			var that = this;

			if(!that._getReepayCustomer()) {
				that._internalError('No Reepay customer');
			}

			if(!that._getReepaySource()) {
				that._internalError('No Reepay source');
			}

			that._isLoading(1);

			$.ajax({
				type: "POST",
				url: apiEndpoint + '?createSubscription',
				data: {
					plan: that._getReepayPlan(),
					source: that._getReepaySource(),
					customer: that._getReepayCustomer(),
					amount: that._getSponsorAmount() // only needed for sponsors, as they choose what amount they'd like to donnate, whereas child sponsors amount are fixed.
				},
				success: function(response) {
					console.log('Got answer', response);

					if(response.error) {
						that._internalError('Something went wrong during createSubscription request', response);
						that._goToStep(7);
					} else {
						// success!
						that._goToStep(6);						
					}
				},
				error: function(response) {
					that._internalError('Something went wrong during createSubscription request', response);

					that._goToStep(7);
				},
				dataType: 'json'
			});
		},

		_openModalCheckout: function() {
			var that = this;

			if(that._getReepayCustomer()) { // we have payment data
				return that._goToStep(6);
			}

			if(!that.rpSessionId) {
				// we need to create customer first
				// TO DO: possibility for endless loop here
				return that._createReepayCustomer();
			}

			that.rp.show(this.rpSessionId);
		},

		_getReepayPlan: function() { // convert plans from this form to appropiate plans in reepay
			var that = this;
			var the_plan;

			var childPlans = {
				'Kenya': {
					'Yearly': 'plan-ebbf8',
					'Half-yearly': 'plan-b46d9',
					'Quarterly': 'plan-e5bf1',
					'Monthly': 'plan-3485a'
				},
				'Thailand': {
					'Yearly': 'plan-520ab',
					'Half-yearly': 'plan-d24f2',
					'Quarterly': 'plan-f58c1',
					'Monthly': 'plan-fc409'
				}
			};

			var supportMemberPlans = {
				'Yearly': 'plan-51edc',
				'Half-yearly': 'plan-3ca0a',
				'Quarterly': 'plan-13528',
				'Monthly': 'plan-474b1'
			}


			if(this.sponsorType == 1) { // sponsoring child
				the_plan = childPlans[that.formFields.childCountry][that.formFields.billingPlan]; // return specific plan for this child and country
			} else { // support member
				the_plan = supportMemberPlans[donationForm.formFields.billingPlan];
			}

			if(!the_plan) {
				that._internalError('Unknown plan!');
				return false;
			} else {
				return the_plan;
			}
		},

		_getReepaySource: function() { // get the reepay source
			return this.rpPaymentMethod;
		},

		_getReepayCustomer: function() { // get reepay customer
			return this.rpCustomerHandle;
		},

		_getSponsorAmount: function() {
			return ((this.formFields.customSponsorAmount) ? this.formFields.customSponsorAmount : this.formFields.sponsorAmount) + '00';
		}
	}

	$(function() {
		window.donationForm = DonationForm.init();

		// playing
		//DonationForm.step = 4;DonationForm._showCurrentStep();
		//DonationForm._goToStep(4);

		BugSnag = bugsnag({
		  apiKey: '5ed0fccb94582c313ce548cd5a5721a0',
		  beforeSend: function (report) {

		    // Add additional diagnostic information
		    report.updateMetaData('user', {
		      formFields: DonationForm.formFields,
		      reepayCustomer: DonationForm._getReepayCustomer()
		    });
		  }
		});
	});

